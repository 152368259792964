
import { computed, defineComponent, PropType } from "vue";
import {
  EelarveAndmik,
  GraphData,
  GraphDataResult,
  ParsedData,
} from "@/firebase/parse/types";
import VaadeChartsPieChart from "@/components/common/VaadeChartsPieChart.vue";
import VaadeChartsProgress from "@/components/common/VaadeChartsProgress.vue";

export default defineComponent({
  name: "VaadeCharts",
  components: {
    VaadeChartsProgress,
    VaadeChartsPieChart,
  },
  emits: {
    onExpand: (code: unknown) => typeof code === "string",
  },
  props: {
    andmik: {
      type: Object as PropType<EelarveAndmik>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    progressTitle: {
      type: String,
      required: true,
    },
    progressSubTitle: {
      type: String,
      required: true,
    },
    expandedCode: {
      type: String,
      default: undefined,
    },
  },
  setup(props, { emit }) {
    const onExpand = (code: string) => emit("onExpand", code);

    const expandedElement = computed<ParsedData | null>(
      () =>
        props.andmik.data.find((el) => el.group === props.expandedCode) || null
    );

    const wholeChartData = computed<GraphData>(() => ({
      result: (props.andmik.data || []).reduce((acc, curr) => {
        if (curr.elements[0].title) {
          acc.push({
            row: curr,
            type: curr.header.title,
            totalValue: curr.elements[0].title,
            currentValue: curr.elements[1].title,
          });
        }
        return acc;
      }, [] as GraphDataResult[]),
      budgetTotal: props.andmik.budgetTotal || 0,
      usedBudgetTotal: props.andmik.usedBudgetTotal || 0,
    }));

    const expandedChartData = computed<GraphData | null>(() => {
      if (!expandedElement.value) return null;
      const element = expandedElement.value;
      const result: GraphDataResult[] = [];
      let budgetTotal = 0;
      let usedBudgetTotal = 0;
      element?.header?.list.forEach((h, index) => {
        const e = element.elements;
        budgetTotal = budgetTotal + e[0].list[index];
        usedBudgetTotal = usedBudgetTotal + e[1].list[index];

        if (e[0].list[index]) {
          result.push({
            row: element,
            type: h,
            totalValue: e[0].list[index],
            currentValue: e[1].list[index],
          });
        }
      });

      return {
        result: result,
        budgetTotal: budgetTotal,
        usedBudgetTotal: usedBudgetTotal,
      };
    });

    const graphData = computed<GraphData>(
      () => expandedChartData.value ?? wholeChartData.value
    );

    return { onExpand, graphData, expandedElement };
  },
});
