<template>
  <div class="py-2 grid grid-cols-2 gap-4 font-sans w-1/4-screen">
    <!-- 1st col -->
    <div class="grid grid-rows-2">
      <!-- 1st row -->
      <div class="break-words text-left font-medium text-black row-span-2">
        <slot></slot>
      </div>
      <!-- 2nd row -->
      <div></div>
    </div>

    <!-- 2nd col -->
    <div class="grid grid-rows-2">
      <!-- 1st row -->
      <div class="h-7 relative max-w-xl rounded overflow-hidden self-center">
        <!-- bar's background -->

        <div class="w-full h-full absolute bg-light-violet"></div>
        <!-- bar's foreground -->
        <div
          ref="bar"
          class="h-full font-semibold z-10 absolute bg-violet-500 w-0"
        >
          <div
            class="px-1.5 h-full flex items-center relative z-20 font-semibold"
            :class="percentage > 85 ? 'text-white' : 'text-violet-500'"
            :style="{
              transform: percentage > 85 ? 'translate(83%)' : 'translate(100%)',
            }"
          >
            {{ percentageStr }}<span class="pl-0.5">%</span>
          </div>
        </div>
      </div>
      <!-- 2nd row -->
      <div class="mt-0.5 w-full inline-flex justify-between font-xs">
        <div>
          <div class="hidden sm:block text-left font-medium text-gray-500">
            {{ currentValueStr }}
          </div>
        </div>
        <div class="text-right font-medium text-gray-700">
          {{ totalValueStr }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from "vue";
import { gsap } from "gsap";
export default defineComponent({
  props: {
    currentValue: {
      type: Number,
      default: 0,
      required: true,
    },
    totalValue: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup(props) {
    const percentage = computed(
      () => (props.currentValue / props.totalValue) * 100
    );
    const bar = ref<HTMLDivElement | null>(null);
    onMounted(() => gsap.to(bar.value, { width: `${percentage.value > 100 ? 100 : percentage.value}%` }));

    const percentageStr = computed(() => percentage.value.toFixed(0));
    const barWidth = computed(
      () => `${percentage.value > 100 ? 100 : percentage.value}%`
    );
    const barStyle = computed(() => ({ width: barWidth.value }));

    const toLocaleFR = (value: number, digits: number) => {
      return value.toLocaleString("fr-FR", {
        maximumFractionDigits: digits,
        style: "currency",
        currency: "EUR",
      });
    };
    const currentValueStr = computed(() => toLocaleFR(props.currentValue, 0));
    const totalValueStr = computed(() => toLocaleFR(props.totalValue, 0));

    return {
      percentageStr,
      barStyle,
      currentValueStr,
      totalValueStr,
      percentage,
      bar
    };
  },
});
</script>
