<template>
  <CardLayout>
    <div v-if="expandedElement" class="text-center">
      <span class="font-semibold text-black text-base">
        {{ expandedElement.header.title }}
      </span>
    </div>
    <span v-else class="uppercase font-medium text-black">
      {{ title }}
    </span>
    <PieChart :values="result" style="height: 32rem" @onExpand="onExpand" />
  </CardLayout>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import CardLayout from "@/components/layout/CardLayout.vue";
import PieChart from "@/components/charts/PieChart.vue";
import { GraphDataResult, ParsedData } from "@/firebase/parse/types";

export default defineComponent({
  name: "VaadeChartsPieChart",
  components: { PieChart, CardLayout },
  emits: {
    onExpand: (code: unknown) => typeof code === "string",
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    result: {
      type: Array as PropType<GraphDataResult[]>,
      required: true,
    },
    expandedElement: {
      type: Object as PropType<ParsedData>,
      required: false,
    },
  },
  setup(props, { emit }) {
    const onExpand = (code: string) => emit("onExpand", code);
    return { onExpand };
  },
});
</script>

<style scoped></style>
