
import { defineComponent, onMounted, ref, PropType, watchEffect } from "vue";
import { arc, pie, select, PieArcDatum } from "d3";
import useResizeObserver from "@/firebase/resizeObserver";
import { ChartData } from "@/components/charts/chart-interfaces";
import { defineColorScale } from "@/components/charts/chart-utils";
import { truncateString } from "@/components/charts/chart-utils";

export default defineComponent({
  components: {},
  props: {
    values: {
      type: Array as PropType<ChartData[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const svgRef = ref();
    const colors = ["#2F989F", "#D56484", "#9E64D5", "#493D6E"];
    const animationDuration = 250;

    const { resizeState, resizeRef } = useResizeObserver();

    onMounted(() => {
      watchEffect(() => {
        const { height, width } = resizeState.value;
        const innerRadius = Math.min(width, height) / 10 + 25;
        const outerRadius = innerRadius + 40;
        const labelRadius = outerRadius + 40;

        const titleFontSize = "16px";
        const percentageFontSize = "18px";
        const totalFontSize = "12px";
        const labelFontSize = "14px";
        const aggregateTotalFontSize = "16px";

        const labelMaxLenght = 30;

        const data = props.values;

        let total = 0;
        data.forEach((e) => {
          total = +total + +e.totalValue;
        });

        select(svgRef.value).select("g").remove();
        const svg = select(svgRef.value);
        const canvas = svg.append("g");
        const art = canvas.append("g");
        const labels = canvas.append("g");

        const d3Pie = pie<ChartData>().value((d) => {
          return (d.totalValue / total) * 100;
        });
        const pieData = d3Pie(data);

        svg.attr("height", height).attr("width", width);

        const pieArc = arc<PieArcDatum<ChartData>>()
          .innerRadius(innerRadius)
          .outerRadius(outerRadius);

        var enteringArcs = art.selectAll(".wedge").data(pieData).enter();

        function calcTranslate(data: any, move = 4) {
          const moveAngle =
            data.startAngle + (data.endAngle - data.startAngle) / 2;
          return `translate(${-move * Math.cos(moveAngle + Math.PI / 2)}, ${
            -move * Math.sin(moveAngle + Math.PI / 2)
          })`;
        }

        let path = enteringArcs
          .append("path")
          .attr("class", "wedge")
          .attr("fill", (d) =>
            defineColorScale(colors, props.values)(d.data.type)
          )
          .attr("d", pieArc)
          .attr("stroke", "white")
          .style("stroke-width", "2px"); // white space between slices

        // https://observablehq.com/@cieloazul310/d3-pie-chart-with-simple-animation#calcTranslate
        path.on("mouseover", (event, v) => {
          select(event.currentTarget)
            .transition()
            .duration(animationDuration)
            .attr("transform", calcTranslate(v, 3));

          select(event.currentTarget)
            .select("path")
            .transition()
            .duration(animationDuration);

          select(".card-back text").text(v.data.type);
        });

        path.on("mouseout", (event) => {
          select(event.currentTarget)
            .transition()
            .duration(animationDuration)
            .attr("transform", "translate(0, 0)");

          select(event.currentTarget)
            .select("path")
            .transition()
            .duration(animationDuration);
        });

        path.on("click", (event, v) => emit("onExpand", v.data?.row?.group));

        const enteringLabels = labels.selectAll(".label").data(pieData).enter();
        const labelGroups = enteringLabels.append("g").attr("class", "label");

        const lines = labelGroups
          .append("line")
          .attr("x1", (d) => pieArc.centroid(d)[0])
          .attr("y1", (d) => pieArc.centroid(d)[1])
          .attr("x2", (d) => {
            const centroid = pieArc.centroid(d);
            const midAngle = Math.atan2(centroid[1], centroid[0]);
            return Math.cos(midAngle) * labelRadius;
          })
          .attr("y2", (d) => {
            const centroid = pieArc.centroid(d);
            const midAngle = Math.atan2(centroid[1], centroid[0]);
            return Math.sin(midAngle) * labelRadius;
          })
          .style("class", "label-line")
          .style("stroke", (d) =>
            defineColorScale(colors, props.values)(d.data.type)
          );

        // generate labels container
        const textLabels: any = labelGroups
          .append("text")
          .attr("x", (d) => {
            const centroid = pieArc.centroid(d);
            const midAngle = Math.atan2(centroid[1], centroid[0]);
            const x = Math.cos(midAngle) * labelRadius;
            const sign = x > 0 ? 1 : -1;
            return x + 5 * sign;
          })
          .attr("y", (d) => {
            const centroid = pieArc.centroid(d);
            const midAngle = Math.atan2(centroid[1], centroid[0]);
            const y = Math.sin(midAngle) * labelRadius;
            return y;
          })
          .style("text-anchor", function (d) {
            const centroid = pieArc.centroid(d);
            const midAngle = Math.atan2(centroid[1], centroid[0]);
            const x = Math.cos(midAngle) * labelRadius;
            return x > 0 ? "start" : "end";
          })
          .style("class", "label-text");

        // adjust labels positioning, greatly inspired by (https://jsfiddle.net/thudfactor/HdwTH/)
        const alpha = 1;
        const spacing = 25;

        const relax = () => {
          let again = false;

          // loop all the text label elements
          textLabels._groups[0].forEach((e: SVGTextElement) => {
            // select the element
            let da = select(e);

            // save its position on the Y axis
            let y1 = parseInt(da.attr("y"));

            // loop again all the text label elements in order to compare each element to the other
            textLabels._groups[0].forEach((f: SVGTextElement) => {
              // if I'm comparing the same element, return
              if (e === f) {
                return;
              }

              // select che element
              let db = select(f);

              // if the element is on a different side of the pieChart, return
              if (da.style("text-anchor") !== db.style("text-anchor")) {
                return;
              }

              // save the element's position on the Y axis
              let y2 = parseInt(db.attr("y"));

              // calculate the difference in positioning between the 2 selected labels
              let deltaY = y1 - y2;

              // if the difference between them is bigger than the chosen spacing
              // they don't collide so, return
              if (Math.abs(deltaY) > spacing) {
                return;
              }

              again = true;

              // calculate if the label should shift up or down based on its position
              // compared to the other label
              let sign = deltaY > 0 ? 1 : -1;

              // asjust the positioning of the 2 labels up and down
              var adjust = sign * alpha;
              da.attr("y", +y1 + adjust);
              db.attr("y", +y2 - adjust);
            });
          });

          // adjust the polylines
          if (again) {
            let labelElements = textLabels._groups[0];
            lines.attr("y2", function (d, i) {
              let labelForLine = select(labelElements[i]);
              return labelForLine.attr("y");
            });
            //setTimeout(relax, 20);
            relax();
          }
        };

        relax();

        // add labels
        textLabels._groups[0].forEach((e: SVGTextElement, i: number) => {
          const a = select(e);
          const x = a.attr("x");
          const y = a.attr("y");

          a.style("font-size", titleFontSize);

          // title
          a.append("tspan")
            .text(() => {
              if (data[i].type.length > labelMaxLenght) {
                return truncateString(data[i].type, labelMaxLenght);
              } else {
                return data[i].type;
              }
            })
            .attr("font-weight", 500);

          // percentage of total
          a.append("tspan")
            .text(" " + ((data[i].totalValue / total) * 100).toFixed(1) + "%")
            .style("fill", () =>
              defineColorScale(colors, props.values)(data[i].type)
            )
            .style("font-size", percentageFontSize)
            .attr("font-weight", 700);

          // total value
          a.append("tspan")
            .attr("x", +x)
            .attr("y", +y + 18)
            .text(
              data[i].totalValue.toLocaleString("fr-FR", {
                style: "currency",
                currency: "EUR",
              })
            )
            .style("fill", "#999999")
            .style("font-size", totalFontSize)
            .attr("font-weight", 500)
            // TODO: what about that?
            .attr("class", "hidden");
        });

        // Add label at the center of the pie chart with totals
        art
          .append("g")
          .attr("class", "font-medium")
          .attr("transform", `translate(${height / 2},${height / 2})`)
          .append("text")
          .attr("y", -height / 2 - 6)
          .attr("x", -height / 2)
          .attr("text-anchor", "middle")
          .text("EELARVE")
          .style("font-size", labelFontSize)
          .style("fill", "#525252");

        art
          .append("g")
          .attr("class", "font-bold font-sans")
          .attr("transform", `translate(${height / 2},${height / 2})`)
          .append("text")
          .attr("y", -height / 2 + 18)
          .attr("x", -height / 2)
          .attr("text-anchor", "middle")
          .text(
            `${total.toLocaleString("fr-FR", {
              maximumFractionDigits: 0,
            })} EUR`
          )
          .style("font-size", total < 100000000 ? aggregateTotalFontSize : 14);

        canvas.attr(
          "transform",
          `translate(${width / 2},
          ${height / 2})`
        );
      });
    });

    return { svgRef, resizeRef };
  },
});
