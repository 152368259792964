<template>
  <CardLayout class="flex flex-wrap">
    <!-- 1st col -->
    <div class="w-full md:w-3/4 order-2 md:order-1">
      <div class="text-gray-500 font-semibold uppercase text-sm">
        {{ progressTitle }}
      </div>
      <div class="mt-6 w-full divide-y divide-gray-200">
        <div v-for="category of graphData.result" :key="category.type">
          <ProgressBar
            :currentValue="category.currentValue"
            :totalValue="category.totalValue"
          >
            {{ category.type }}
          </ProgressBar>
        </div>
      </div>
    </div>

    <!-- 2nd col -->
    <div
      class="w-full md:w-1/4 order-1 md:order-2 text-center text-gray-500 font-semibold uppercase text-sm"
    >
      {{ progressSubTitle }}
      <CircularProgressBar
        class="mt-4 flex justify-center text-lg"
        :value="circularProgressBarValue"
      />
    </div>
  </CardLayout>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue";
import CardLayout from "@/components/layout/CardLayout.vue";
import CircularProgressBar from "@/components/charts/CircularProgressBar.vue";
import { GraphData } from "@/firebase/parse/types";

import ProgressBar from "@/components/charts/ProgressBar.vue";

export default defineComponent({
  name: "VaadeChartsProgress",
  components: {
    ProgressBar,
    CircularProgressBar,
    CardLayout,
  },
  props: {
    graphData: {
      type: Object as PropType<GraphData>,
      required: true,
    },
    progressTitle: {
      type: String,
      required: true,
    },
    progressSubTitle: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const circularProgressBarValue = computed(() => {
      if (!props.graphData.budgetTotal) return 0;
      return (
        (props.graphData.usedBudgetTotal / props.graphData.budgetTotal) * 100
      );
    });
    return { circularProgressBarValue };
  },
});
</script>

<style scoped></style>
