
import { computed, defineComponent, PropType } from "vue";
import CardLayout from "@/components/layout/CardLayout.vue";
import CircularProgressBar from "@/components/charts/CircularProgressBar.vue";
import { GraphData } from "@/firebase/parse/types";

import ProgressBar from "@/components/charts/ProgressBar.vue";

export default defineComponent({
  name: "VaadeChartsProgress",
  components: {
    ProgressBar,
    CircularProgressBar,
    CardLayout,
  },
  props: {
    graphData: {
      type: Object as PropType<GraphData>,
      required: true,
    },
    progressTitle: {
      type: String,
      required: true,
    },
    progressSubTitle: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const circularProgressBarValue = computed(() => {
      if (!props.graphData.budgetTotal) return 0;
      return (
        (props.graphData.usedBudgetTotal / props.graphData.budgetTotal) * 100
      );
    });
    return { circularProgressBarValue };
  },
});
