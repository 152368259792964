
import { defineComponent, computed, ref, onMounted } from "vue";
import { gsap } from "gsap";
export default defineComponent({
  props: {
    currentValue: {
      type: Number,
      default: 0,
      required: true,
    },
    totalValue: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  setup(props) {
    const percentage = computed(
      () => (props.currentValue / props.totalValue) * 100
    );
    const bar = ref<HTMLDivElement | null>(null);
    onMounted(() => gsap.to(bar.value, { width: `${percentage.value > 100 ? 100 : percentage.value}%` }));

    const percentageStr = computed(() => percentage.value.toFixed(0));
    const barWidth = computed(
      () => `${percentage.value > 100 ? 100 : percentage.value}%`
    );
    const barStyle = computed(() => ({ width: barWidth.value }));

    const toLocaleFR = (value: number, digits: number) => {
      return value.toLocaleString("fr-FR", {
        maximumFractionDigits: digits,
        style: "currency",
        currency: "EUR",
      });
    };
    const currentValueStr = computed(() => toLocaleFR(props.currentValue, 0));
    const totalValueStr = computed(() => toLocaleFR(props.totalValue, 0));

    return {
      percentageStr,
      barStyle,
      currentValueStr,
      totalValueStr,
      percentage,
      bar
    };
  },
});
